<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-history"></i> Asset History Log</b-card-title>
			<b-card-sub-title>History logs for all changes in asset status</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="my-1">
							<b>FILTER OPTIONS</b>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col lg="4" md="10" sm="12" class="mr-4">
							<b-form-group label="Asset Code"
								description="Please enter the exact asset code here. (i.e. P000001)">
								<b-form-input id="assetId" name="Asset Id" type="search" class="numFont"
									v-model="filterBy.assetId" v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
								<span v-show="errors.has('Asset Id')" class="help-block">{{
									errors.first('Asset Id')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col sm="12">
							<b-button class="mr-1" variant="success" @click="searchHistory">
								Search
							</b-button>
							<b-button class="mr-1" variant="primary" @click="resetFilters">
								Reset
							</b-button>
						</b-col>
					</b-row>
				</b-card>

				<div v-if="hasResult">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown text=" Select Actions " variant="dark" slot="append">
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="xls"
											:name="fileName + '.xls'">Export Asset History Log in Excel</json-excel>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel :data="exportData" :fields="exportFields" type="csv"
											:name="fileName + '.csv'">Export Asset History Log to CSV</json-excel>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-right-md">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>

					<b-row>
						<b-col sm="8" class="my-2">
							<b>ASSET HISTORY LOG FOR</b>&nbsp;
							<b-badge variant="primary numFont">{{ searchedAssetCode }}</b-badge>
						</b-col>
					</b-row>

					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" :sort-compare="sortCompare" responsive />
					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
								v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { SortUtil } from '@/utils/sortUtil';

// Database
import assetDAO from '@/database/assets';
import taggedAssetDAO from '@/database/taggedAssets';

// Others
import config from '@/config/env-constants';
import Datepicker from 'vuejs-datepicker';
import JsonExcel from 'vue-json-excel';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'asset-history-log',
	components: {
		JsonExcel,
		Datepicker,
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dateCreated',
					sortable: true,
				},
				{
					key: 'operation',
				},
				{
					key: 'oldStatus',
				},
				{
					key: 'newStatus',
				},
				{
					key: 'origin',
					label: 'Owner',
					sortable: true,
				},
				'currentLocation',
				'createdBy',
				'notes',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: 'dateCreated',
			sortDesc: false,
			sortDirection: 'desc',
			filter: null,

			filterBy: {
				assetId: '',
			},

			searchedAssetCode: '',

			allAssetTypesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			hasResult: false,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Asset Id': 'assetId',
				Operation: 'operation',
				'Old Status': 'oldStatus',
				'New Status': 'newStatus',
				'Current Location': 'currentLocation',
				'Next Location': 'nextLocation',
				Origin: 'origin',
				'Current Company': 'currCompany',
				'Date Created': 'dateCreated',
				'Logged By': 'createdBy',
				Notes: 'notes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'AssetHistoryLog-' + DateUtil.getDateInMMDDYYYYFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(() => {
			// Filter Access
			if (this.$store.getters.isAccounting
				|| this.$store.getters.isApprover
				|| this.$store.getters.isMaintenance) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}

			this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
		}, config.timeout);
	},
	methods: {
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},

		async validateParam(assetCode) {
			let validateResult = {
				isSuccess: true,
				errorMsg: '',
			};

			if (assetCode.length === 0) {
				validateResult.isSuccess = false;
				validateResult.errorMsg = 'Asset Code is required.';
			} else if (!this.isValidAssetCode(assetCode)) {
				validateResult.isSuccess = false;
				validateResult.errorMsg = `Invalid Asset Code. "${assetCode}" doesn't follow any of your asset tagging format.`;
			}

			if (this.isSuperAdmin || !validateResult.isSuccess) {
				return validateResult;
			} else {
				let isAccountable = await this.isCompanyAccountableToAsset(assetCode);
				if (!isAccountable) {
					validateResult.isSuccess = false;
					validateResult.errorMsg =
						'Invalid Access. You are not associated to this asset code.';
				}
				return validateResult;
			}
		},

		async isCompanyAccountableToAsset(assetCode) {
			let assetObj = await assetDAO.getAssetById(assetCode);

			// if non-existent
			if (_.isEmpty(assetObj)) {
				return false;
			}

			// if owner company
			if (assetObj.originId === this.loggedUserCompany.id) {
				return true;
			} else {
				// if non-owner company, get accountability status
				let taggedAssetObjResults = await taggedAssetDAO.getTaggedAssetsFromAssetCode([assetCode]);
				let taggedAssetObj = taggedAssetObjResults[0];

				return !_.isEmpty(taggedAssetObj);
			}
		},
		sortCompare(rowA, rowB, key) {
			switch (key) {
				case 'dateCreated':
					return new Date(rowB.dateCreated) - new Date(rowA.dateCreated);
				default:
					return null;
			}
		},
		async searchHistory() {
			let assetCode = this.filterBy.assetId;

			let validateResult = await this.validateParam(assetCode);
			if (!validateResult.isSuccess) {
				this.$toaster.warning(validateResult.errorMsg);

				// Hide Search Results
				this.hasResult = false;
				this.searchedAssetCode = '';
				return;
			}

			// Show Loading Indicator
			this.isLoading = true;

			try {
				this.items = [];

				let historiesObj = await assetDAO.getAssetHistories(assetCode);
				_.forEach(historiesObj, history => {
					let item = {
						assetId: history.assetId,
						operation: history.operation,
						oldStatus: this.getStatus(history.oldStatus),
						newStatus: history.newStatus,
						currentLocation: this.getLocationName(
							history.currentLocation
						),
						nextLocation: this.getLocationName(history.nextLocation),
						origin: this.getEntityName(history.origin),
						currCompany: this.getEntityName(history.currCompany),
						dateCreated: DateUtil.getFormattedDateWithTime(
							history.dateCreated
						),
						notes: history.notes,
						createdBy: history.createdBy,
					};

					this.items.push(item);
				});

				this.totalRows = this.items.length;
				this.items = SortUtil.sortByDateDesc('dateCreated', this.items);

				// Show Search Results
				this.hasResult = true;
				this.searchedAssetCode = assetCode;
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		getStatus(status) {
			if (!status) {
				return '-';
			}
			return status;
		},
		getLocationName(loc) {
			if (loc && loc.company && loc.storageLocation) {
				return loc.company + ' - ' + loc.storageLocation;
			}
			return '-';
		},
		getEntityName(entity) {
			if (entity) {
				return entity.name;
			}
			return '-';
		},
		resetFilters() {
			this.filterBy = {
				dateCreated: null,
				assetId: '',
			};

			this.hasResult = false;

			// reset item content
			this.items = [];
		},
	},
};
</script>