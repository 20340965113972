export const SortUtil = {

	sort(arrayToSort) {

		let compare = function (a, b) {
			if (a < b)
				return -1;
			if (a > b)
				return 1;
			return 0;
		}

		arrayToSort.sort(compare);

		return arrayToSort;
	},

	sortByAttr(fieldName, arrayToSort) {

		let compare = function (a, b) {
			if (a[fieldName].trim() < b[fieldName].trim())
				return -1;
			if (a[fieldName].trim() > b[fieldName].trim())
				return 1;
			return 0;
		};

		arrayToSort.sort(compare);

		return arrayToSort;
	},

	sortByAttrDesc(fieldName, arrayToSort) {

		let compare = function (a, b) {
			if (a[fieldName].trim() < b[fieldName].trim())
				return 1;
			if (a[fieldName].trim() > b[fieldName].trim())
				return -1;
			return 0;
		};

		arrayToSort.sort(compare);

		return arrayToSort;
	},

	sortByDate(_fieldName, arrayToSort) {

		let compare = function (a, b) {
			return new Date(b.dateCreated) - new Date(a.dateCreated);
		};

		arrayToSort.sort(compare);

		return arrayToSort;
	},

	sortByDateDesc(_fieldName, arrayToSort) {

		let compare = function (a, b) {
			return new Date(a.dateCreated) - new Date(b.dateCreated);
		};

		arrayToSort.sort(compare);

		return arrayToSort;
	},

}
