import { db } from '@/config/firebase';


async function getTaggedAssetsFromAssetCode(assetCodes) {
    let dbRef = [];

    for (const assetCode of assetCodes) {
        let taggedAssetRef = db.collectionGroup('taggedAssets').where('assetCode', '==', assetCode).get()
        dbRef.push(taggedAssetRef);
    }

    return Promise.all(dbRef).then(results => {
        let taggedAssetsObj = {};
        results.forEach(querySnapshot => {
            querySnapshot.forEach(doc => {
                if (doc.exists) {
                    let taggedAsset = doc.data();
                    taggedAsset.id = doc.id;
                    taggedAssetsObj[taggedAsset.id] = taggedAsset;
                }
            });
        });

        return Promise.all([taggedAssetsObj]);
    });
}


export default {
    getTaggedAssetsFromAssetCode,
}